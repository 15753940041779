import React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import {Parallax} from "react-scroll-parallax";
import Menu1 from '../images/inline/menu_11.inline.svg'
import Menu2 from '../images/inline/menu_22.inline.svg'
import {Link} from "gatsby";
const ServiciosPage = () => {
    return (
        <Layout>
            {/*
                        <div className="servicios-menu">
                            <div className="item">
                                <Link to={'/servicio-digitalizacion-procesos'}>
                                    <h5 className={'especial2'}>Digitalización y Automatización de Procesos</h5>
                                </Link>
                                <div className="content">
                                    <Menu1/>
                                    <p className={'contenido1'}>Permite automatizar los procesos de negocio, mediante
                                        conexiones de la captura de documentos con los sistemas empresariales.</p>
                                </div>
                                <Link to={'/servicio-digitalizacion-procesos'} className={'more'}>
                                    Ver Más
                                </Link>
                            </div>
                            <div className="item">
                                <Link to={'/servicio-administrados-impresion'}>
                                    <h5 className={'especial2'}>Administrados de Impresión</h5>
                                </Link>
                                <div className="content">
                                    <Menu2/>
                                    <p className={'contenido1'}>Construimos un ambiente monitoreado, administrado y
                                        controlado con la finalidad de apoyar procesos de negocio además de las
                                        necesidades de impresión. .</p>
                                </div>
                                <Link to={'/servicio-administrados-impresion'} className={'more'}>
                                   <span>Ver Más</span>
                                </Link>
                            </div>
                        </div>
            */}

            <div className={'service image-info image-visible'} data-sal="slide-up">
                <div className={'bloqueleft40 left float'}>
                    <Menu1 className={'img'}/>
                </div>
                <div className={'bloqueright2servicios right mayoristas'}>
                    <h4 className={'h4servicios'}>Digitalización y Automatización de Procesos.</h4>
                        <p className="especial3">
                            Permite automatizar los procesos de negocio, mediante
                            conexiones de la captura de documentos con los sistemas empresariales.
                        </p>
                    <Link to={'/servicio-digitalizacion-procesos/'} className="btn-push-black"><span>Ver más</span></Link>
                </div>
            </div>

            <div className={'service service-last image-info inverted image-visible'}>
                <div className={'left'}>
                    <h4 className={'h4servicios'}>Administrados de Impresión.</h4>
                    <p className="especial3">
                        Construimos un ambiente monitoreado, administrado y
                        controlado con la finalidad de apoyar procesos de negocio además de las
                        necesidades de impresión.
                    </p>
                    <Link to={'/servicio-administrados-impresion'} className="btn-push-black"><span>Ver más</span></Link>
                </div>
                <div data-sal="slide-up" className={'right'}>
                    <Menu2 className={'img'}/>
                </div>
            </div>

        </Layout>
    )
}
export default ServiciosPage

export const Head = () => <title>T.I.O. ALEMAR | Servicios</title>
